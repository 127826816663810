import React from 'react';
import { useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';
import { Flex, Dialog, Button, Textarea } from '@applyboard/crystal-ui';

export function VisaOutcomeRequest() {
  const [open, setOpen] = React.useState(false);
  const { isEnabled } = useAdditionalDocumentRequest();

  if (!isEnabled) return null;

  return (
    <>
      <Button width="fill" intent="primary" emphasis="outlined" onClick={() => setOpen(true)}>
        Request Visa Outcome
      </Button>
      {open && <VisaOutcomeDialog onClose={() => setOpen(false)} />}
    </>
  );
}

export function VisaOutcomeDialog(props: { onClose: () => void }) {
  const { addUpdateDocuments, documents } = useAdditionalDocumentRequest();
  const sectionReference = 'other';
  const type = 'VISA_OUTCOME';

  const activeDocumentReq = React.useMemo(
    () => documents.find((f) => f.sectionReference === sectionReference && f.type === type),
    [documents, type, sectionReference]
  );
  const [comment, setComment] = React.useState(() => activeDocumentReq?.comment || '');

  const onSave = React.useCallback(() => {
    addUpdateDocuments([{ type, comment, sectionReference }]);
    props.onClose();
  }, [addUpdateDocuments, comment, sectionReference, type, props]);

  return (
    <Flex justify="start" direction="column" gap={8}>
      <Dialog
        open={true}
        onOpenChange={(open) => {
          if (!open) props.onClose();
          if (open) {
            setComment(activeDocumentReq?.comment || '');
          } else {
            setComment('');
          }
        }}
      >
        <Dialog.Heading>Request Visa Outcome</Dialog.Heading>
        <Dialog.Content>
          <Flex gap={6} direction="column">
            <Textarea label="Notes for student" value={comment} onChange={(value) => setComment(value)} size="sm" />
          </Flex>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
          <Button intent="primary" width="fill" onClick={onSave} disabled={!comment.trim()}>
            Save
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Flex>
  );
}
